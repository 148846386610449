import React from "react";
// MUI Components
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
//Content Video
import animation from "./content/animation.mp4";

export default function OpeningApp() {
    const [open, setOpen] = React.useState(true);
    React.useEffect(() => {
        open && setTimeout(() => setOpen(false), 6500);
    }, [open]);

    const [showTitle, setShowTitle] = React.useState(false);
    const [style, setStyle] = React.useState({ opacity: 1 });
    React.useEffect(() => {
        !showTitle && setTimeout(() => setShowTitle(true), 2800);
        showTitle &&
            setStyle({
                opacity: 0,
                transitionProperty: "opacity",
                transitionDuration: "1.5s",
                transitionDelay: "2s",
            });
    }, [showTitle]);

    return (
        <Backdrop
            open={open}
            transitionDuration={0}
            style={style}
            sx={{
                //bgcolor: "primary.main",
                zIndex: 99999,
                flexDirection: "column",
                background: "#182156",
            }}
            unmountOnExit
        >
            <Box
                component={"video"}
                src={animation}
                type="video/mp4"
                muted
                autoPlay
                sx={{ width: "100%", maxHeight: "100%" }}
            />
        </Backdrop>
    );
}
